import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentPageIndex from "../components/contentPageIndex"

const IndexPage = ({location}) => (
  <Layout location={location}>
    <SEO title="Accueil" description="Excursions et randonnées dans le Jura. Animation musicale : soirées dansantes, karaokés, ..." />
    <ContentPageIndex />
  </Layout>
)

export default IndexPage
